<template>
  <div>
    <template v-if="isLgAndUp || (isLgAndDown && isManyDaysWithOneSeance)">
      <ul
        :class="[
          { 'grid-cols-1 gap-1.5': seancesByDay.length === 1 || isManyDaysWithOneSeance },
          { 'grid-cols-5': isLgAndUp },
          { 'grid-cols-4 gap-1': isLgAndDown && !isManyDaysWithOneSeance }
        ]"
        class="relative grid justify-around"
      >
        <li v-for="(seance, index) in seancesByDay" :key="seance.length + index">
          <SeanceCard
            :value="seance"
            :event-id="eventId"
            @open-timetable="emit('openTimetable', $event)"
          />
        </li>
        <li
          v-if="isLgAndUp || seancesGroupedByDay.length > MAX_SEANCES"
          class="sticky right-0 top-0 h-fit w-full"
        >
          <SeanceTimetableButton
            :event-id="eventId"
            :is-many-days-with-one-seance="isManyDaysWithOneSeance"
            @open="emit('openTimetable', $event)"
          />
        </li>
      </ul>
    </template>
    <template v-else>
      <FilterSeancesPreviewByDay
        :model-value="activeSeanceCheckbox"
        :value="seancesByDay"
        type="radio"
        :class="{
          'grid-cols-7': MAX_SEANCES_FOR_TABLET_MANY_DAYS_WITH_MANY_SEANCES === 6,
          'grid-cols-5': MAX_SEANCES_FOR_TABLET_MANY_DAYS_WITH_MANY_SEANCES === 4,
          '!grid-cols-4': !isSmAndUp
        }"
        class="grid gap-1"
        @update:model-value="changeActiveSeance"
      >
        <template #more-button>
          <SeanceTimetableButton
            v-if="seancesGroupedByDay.length > MAX_SEANCES"
            :event-id="eventId"
            :is-many-days-with-one-seance="isManyDaysWithOneSeance"
            @open="emit('openTimetable', $event)"
          />
        </template>
      </FilterSeancesPreviewByDay>

      <template v-if="selectedSeances">
        <SeanceListNew
          :value="selectedSeances"
          splitter
          class="mt-3 overflow-hidden rounded-xl border border-card-primary shadow-card_primary_hover"
          :max-items="MAX_SELECTED_SEANCES"
          :event-id="eventId"
          @open-timetable="emit('openTimetable', $event)"
        />
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import type { Event } from '@/5_entities/Event'
import { type Seance, SeanceCard, SeanceTimetableButton } from '@/5_entities/Seance'
import { SeanceListNew, getSeancesByDay } from '@/5_entities/Seance'
import { useQueryParamsOpenState } from '@/6_shared/composables'
import { useBreakpoint } from '@/6_shared/lib'
import FilterSeancesPreviewByDay from './FilterSeancesPreviewByDay.vue'

type PropType = {
  eventId: Event['id']
  value: Seance[]
  valueCount: number
}
type EmitType = {
  (e: 'openTimetable', eventId: Event['id']): void
}

const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()

const {
  lg: [isLgAndUp, isLgAndDown],
  sm: [isSmAndUp],
  md: [_, isMdAndDown]
} = useBreakpoint()

const MAX_SEANCES_FOR_MANY_DAYS_WITH_ONE_SEANCE = 5
const MAX_SEANCES_FOR_MOBILE_MANY_DAYS_WITH_MANY_SEANCES = 3
const MAX_SEANCES_FOR_TABLET_MANY_DAYS_WITH_MANY_SEANCES = computed(() => {
  if (isMdAndDown.value) return 4
  if (isLgAndDown.value) return 6

  return 4
})
const MAX_SEANCES_DESKTOP = 4

/**
 * число отображаемых сеансов
 */
const MAX_SEANCES = computed(() => {
  if (isLgAndUp.value) return MAX_SEANCES_DESKTOP

  return isManyDaysWithOneSeance.value
    ? MAX_SEANCES_FOR_MANY_DAYS_WITH_ONE_SEANCE
    : isSmAndUp.value
      ? MAX_SEANCES_FOR_TABLET_MANY_DAYS_WITH_MANY_SEANCES.value
      : MAX_SEANCES_FOR_MOBILE_MANY_DAYS_WITH_MANY_SEANCES
})

/**
 * все сеансы проходят только в 1 день
 * @param seancesByDay
 * @return boolean
 */
const computeIsManyDaysWithOneSeance = (seancesByDay: Seance[][]) => {
  for (const seances of seancesByDay) {
    if (seances.length > 1) return false
  }

  return true
}
/**
 * сеансы по дням
 */
const seancesGroupedByDay = computed(() => getSeancesByDay(props.value))

/**
 * сеансы по дням, отфильтрованные по кличеству
 */
const seancesByDay = computed(() => {
  const isManyDaysWithOneSeance = computeIsManyDaysWithOneSeance(seancesGroupedByDay.value)

  return seancesGroupedByDay.value.filter((_item, index) => {
    if (isLgAndUp.value) return index < MAX_SEANCES_DESKTOP

    return isManyDaysWithOneSeance
      ? index < MAX_SEANCES_FOR_MANY_DAYS_WITH_ONE_SEANCE
      : isSmAndUp.value
        ? index < MAX_SEANCES_FOR_TABLET_MANY_DAYS_WITH_MANY_SEANCES.value
        : index < MAX_SEANCES_FOR_MOBILE_MANY_DAYS_WITH_MANY_SEANCES
  })
})

/**
 * в каждый день только по 1 сеансу
 */
const isManyDaysWithOneSeance = computed(() => computeIsManyDaysWithOneSeance(seancesByDay.value))

/**
 * выбранный сеанс для мобилы
 */
const activeSeanceCheckbox = ref<Seance['id'] | undefined>(seancesByDay.value[0][0].id || undefined)
const route = useRoute()
const { setParamInRouteQuery } = useQueryParamsOpenState('activeSeanceDay')

const changeActiveSeance = (newActive: Seance['id'] | undefined) => {
  activeSeanceCheckbox.value = newActive

  setParamInRouteQuery(String(activeSeanceCheckbox.value), 'replace')
}

onMounted(() => {
  const seanceId = route.query.activeSeanceDay && Number(route.query.activeSeanceDay)

  seanceId && (activeSeanceCheckbox.value = seanceId)
})
/**
 * список сеансов, отфлиьтрованный по дню
 */
const selectedSeances = computed(() =>
  seancesByDay.value.find(([seance]) => seance.id === activeSeanceCheckbox.value)
)
const MAX_SELECTED_SEANCES = 6
</script>
