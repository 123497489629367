<template>
  <div class="h-full animate-pulse">
    <div class="flex flex-col lg:flex-row">
      <div class="top-0 h-fit w-full lg:sticky lg:max-w-[280px]">
        <ul class="flex gap-2 overflow-hidden p-4 lg:grid lg:grid-cols-2">
          <li
            v-for="item in 4"
            :key="item"
            class="flex w-full min-w-[104px] cursor-pointer select-none flex-col rounded-lg border border-card-primary bg-primary px-2 py-1 shadow-card_primary transition-all peer-hover:border-card-primary-hover peer-hover:shadow-card_primary_hover peer-focus:shadow-card_primary_hover lg:min-w-1 lg:shadow-none"
          >
            <div class="mb-1 h-6 w-8 rounded-lg bg-secondary" />
            <div class="mb-1 h-4 w-10 rounded-lg bg-secondary" />
            <div class="mb-1 h-4 w-12 rounded-lg bg-secondary" />
          </li>
        </ul>
      </div>
      <div class="relative size-full p-4.5 pt-0 lg:pt-4.5">
        <div class="gradient-card absolute inset-0 hidden size-full lg:block" />
        <div v-for="item in 15" :key="item + 'item'" class="mb-4">
          <div class="mb-1 h-5 w-24 rounded-lg bg-quaternary lg:bg-secondary" />
          <div class="mb-1 h-3 w-32 rounded-lg bg-quaternary lg:bg-secondary" />
          <ul class="flex flex-wrap gap-2">
            <li
              v-for="card in 4"
              :key="card + 'card'"
              class="flex w-full cursor-pointer select-none flex-col rounded-lg border border-card-primary bg-primary px-2 py-1 shadow-card_primary transition-all peer-hover:border-card-primary-hover lg:min-w-1 lg:max-w-1/5 lg:shadow-none"
            >
              <div class="mb-1 h-6 w-8 rounded-lg bg-secondary" />
              <div class="mb-1 h-4 w-12 rounded-lg bg-secondary" />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
